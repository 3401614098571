import React, { useContext, useEffect, useState } from 'react'
import { Button, makeStyles, shorthands, tokens, Text } from '@fluentui/react-components'
import { t } from 'i18next'
import { Demand, QuestionStatus } from '../../api/schemas/schema'
import { fakeIdGenerator } from '../../helpers/demandHelper'
import BasicAccordion from '../common/accordion/BasicAccordion'
import { Add16Regular } from '@fluentui/react-icons'
import { useForm } from 'react-hook-form'
import { DataContext } from '../../App'
import { Modal } from '../common/modal/Modal'
import { QuestionFormValues, addQuestion, saveQuestion } from '../../helpers/questionHelper'
import { useSearchParams } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'
import { QuestionCard } from './QuestionCard'
import { ToggleButton } from '../common/inputs/ToggleButton'

const useStyles = makeStyles({
   midRigth: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
   content: {
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.gap(tokens.spacingVerticalL),
      ...shorthands.margin(tokens.spacingVerticalL, 0),
   },
   header: {
      display: 'flex',
      ...shorthands.gap(tokens.spacingHorizontalS),
   },
})
type SelectedQuestionProps = {
   demand: Demand
}

export const SelectedQuestion = ({ demand }: SelectedQuestionProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { questions, demands } = dataContext.state
   const [searchParams, setSearchParams] = useSearchParams()
   const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
   const [openModal, setOpenModal] = useState<'new' | 'badgeUndo' | 'undo'>(null)
   const questionForm = useForm<QuestionFormValues>({
      defaultValues: {
         selectedQuestion: null,
         questions: [],
      },
   })
   const {
      reset,
      watch,
      handleSubmit,
      formState: { isDirty },
   } = questionForm
   const { accounts } = useMsal()
   useEffect(() => {
      const demandQuestions = questions.filter((x) => x.parentId === demand.id)

      let order = Number(searchParams.get('question') ?? demandQuestions[0]?.order)

      if (demandQuestions.length > 0 && !demandQuestions.find((x) => Number(searchParams.get('question')) === x.order)) {
         searchParams.delete('question')
         setSearchParams(searchParams)
      }
      reset({ selectedQuestion: demandQuestions.find((x) => x.order === order), questions: demandQuestions })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [demand.id, questions])

   const onSubmit = async (data: QuestionFormValues) => {
      data.selectedQuestion.modifiedBy = accounts[0].name
      data.selectedQuestion.modifiedDate = new Date().toISOString()
      await saveQuestion(setIsSubmitting, dataContext, data, reset, t)
   }
   const onUndoClick = () => {
      reset((prev) => ({ ...prev, selectedQuestion: questions.find((x) => x.id === watch('selectedQuestion.id')) }))
      setOpenModal(null)
   }

   const isDisabled = watch('selectedQuestion.status') === QuestionStatus.Archived
   return (
      <BasicAccordion
         headerText={`${t('QuestionsForDemand')} - ${fakeIdGenerator(
            demand,
            t,
            demands.find((x) => x.id === demand.parentId)
         )}`}
         className={classes.midRigth}
         actions={
            <Button
               size="small"
               icon={<Add16Regular />}
               appearance="transparent"
               onClick={async () => {
                  if (isDirty || watch('selectedQuestion.id') === null) {
                     setOpenModal('new')
                  } else {
                     addQuestion(dataContext, reset, setOpenModal, accounts[0].name, demand.id)
                  }
               }}
               disabled={isDisabled}
            >
               {t('NewQuestion')}
            </Button>
         }
      >
         <form onSubmit={handleSubmit(onSubmit)} className={classes.content}>
            <header className={classes.header}>
               {watch('questions').map((x, i) => (
                  <ToggleButton
                     key={i}
                     selected={x.id === watch('selectedQuestion.id')}
                     onClick={() => {
                        if (isDirty || watch('selectedQuestion.id') === null) {
                           setOpenModal('badgeUndo')
                        } else {
                           reset((prev) => ({ ...prev, selectedQuestion: x }))
                        }
                     }}
                  >
                     {`${t('Question')} ${x.order}`}
                  </ToggleButton>
               ))}
            </header>
            {watch('selectedQuestion') ? (
               <QuestionCard
                  questionForm={questionForm}
                  setIsSubmitting={setIsSubmitting}
                  isSubmitting={isSubmitting}
                  setOpenModal={setOpenModal}
                  isDisabled={isDisabled}
                  parentDemand={demand}
               />
            ) : (
               <Text>{watch('questions').length > 0 ? t('NoQuestionChosen') : t('NoQuestionsCreated')}</Text>
            )}
         </form>
         {openModal !== null && (
            <Modal
               open={openModal !== null}
               title={
                  openModal === 'new'
                     ? `${t('Warning')}! ${t('LatestChangesWillBeUndone')}`
                     : openModal === 'badgeUndo'
                     ? t('SaveBeforeProceed')
                     : t('UndoChanges')
               }
               actions={
                  <>
                     <Button onClick={() => setOpenModal(null)}>{t('Cancel')}</Button>
                     {openModal !== 'badgeUndo' && (
                        <Button
                           onClick={() => (openModal === 'new' ? addQuestion(dataContext, reset, setOpenModal, accounts[0].name, demand.id) : onUndoClick())}
                           appearance="primary"
                        >
                           {t('Continue')}
                        </Button>
                     )}
                  </>
               }
               children={
                  openModal === 'new'
                     ? t('AreYouSureLatestChangesWillBeUndone')
                     : openModal === 'badgeUndo'
                     ? t('FormHasUnsavedChanges')
                     : t('UndoQuestionChanges')
               }
            />
         )}
      </BasicAccordion>
   )
}
