import React from 'react'
import { Api, Demand, DemandStatus } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { handleMessage } from './stateHelper'
import { TFunction } from 'i18next'
const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })

export const expandDemand = (dataContext: IDataContext, items: Demand[], rowKey: string): Demand[] => {
   const { demands, selectedTab } = dataContext.state
   const newArray = [...items]
   const index = newArray.findIndex((x) => x.id === rowKey)
   if (index >= 0) {
      newArray.splice(
         index + 1,
         0,
         ...demands.filter(
            (d) => d.parentId === rowKey && (selectedTab === 'activeDemands' ? d.status !== DemandStatus.Archived : d.status === DemandStatus.Archived)
         )
      )
      return newArray
   }
   return items
}

export const expandDemands = (dataContext: IDataContext, items: Demand[]) => {
   const { expandedRows } = dataContext.state
   let allItems = items

   Object.keys(expandedRows).forEach((id) => {
      if (expandedRows[id]) allItems = expandDemand(dataContext, allItems, id)
   })

   return allItems
}

export const toggleRowExpansion = (dataContext: IDataContext, setItems: React.Dispatch<React.SetStateAction<Demand[]>>, items: Demand[], rowKey: string) => {
   const { expandedRows } = dataContext.state
   const childItems = items.filter((x) => x.parentId === rowKey)

   const rowkeysToToggle: any[] = [rowKey]
   childItems.forEach((x) => {
      if (expandedRows[x.id]) {
         rowkeysToToggle.push(x.id)
      }
   })
   let updatedExpandedRows = expandedRows
   let filteredItems = [...items]
   rowkeysToToggle.forEach((rowKey) => {
      if (expandedRows[rowKey]) {
         filteredItems = filteredItems.filter((i) => i.parentId !== rowKey)
      } else {
         filteredItems = expandDemand(dataContext, items, rowKey)
      }
      updatedExpandedRows = { ...updatedExpandedRows, [rowKey]: !updatedExpandedRows[rowKey] }
   })
   setItems(filteredItems)
   dataContext.setRootState((prev) => ({
      ...prev,
      expandedRows: updatedExpandedRows,
   }))
}

export const resetItems = (setItems: React.Dispatch<React.SetStateAction<Demand[]>>, items: Demand[], droppedItem: Demand, index: number) => {
   const newArray = [...items]
   newArray.splice(index, 1)
   if (droppedItem.level === 1) {
      const föreFörälder = newArray.find((x) => x.level === 1 && x.order === droppedItem.order - 1)
      const föreföräderIndex = föreFörälder ? newArray.findIndex((x) => x.id === föreFörälder.id) : -1
      let counter = 0
      items
         .filter((x) => x.parentId === föreFörälder?.id)
         // eslint-disable-next-line array-callback-return
         .map((x) => {
            counter += 1
            items.filter((c) => c.parentId === x.id).map((x) => (counter += 1))
         })
      newArray.splice(föreföräderIndex + counter + 1, 0, droppedItem)
   } else {
      const droppedItemParent = newArray.find((x) => x.id === droppedItem.parentId)
      const droppedItemParentIndex = newArray.findIndex((x) => x.id === droppedItemParent.id)
      let droppedItemParentChildren = 0
      items
         .filter((x) => x.parentId === droppedItemParent?.id && x.order < droppedItem.order)
         // eslint-disable-next-line array-callback-return
         .map((x) => {
            droppedItemParentChildren += 1
            items.filter((c) => c.parentId === x.id).map((x) => (droppedItemParentChildren += 1))
         })

      newArray.splice(droppedItemParentIndex + droppedItemParentChildren + 1, 0, droppedItem)
   }

   setItems(newArray)
}
export const handleDrop = async (
   t: TFunction<'translation', undefined>,
   dataContext: IDataContext,
   setItems: React.Dispatch<React.SetStateAction<Demand[]>>,
   items: Demand[],
   droppedItem: Demand
) => {
   const { selectedTab, demands } = dataContext.state
   const relevantaItems = items.filter((x) => x.level === droppedItem.level && x.parentId === droppedItem.parentId)
   const parentItem = droppedItem.level === 1 ? undefined : items.find((x) => x.id === droppedItem.parentId)
   const parentItemIndex = droppedItem.level === 1 ? undefined : items.findIndex((x) => x.id === droppedItem.parentId)
   const index = items.findIndex((x) => x.id === droppedItem.id)

   if (selectedTab === 'archivedDemands') {
      handleMessage(dataContext.setRootState, 'warning', t('ActionNotSupported'), t('CantDNDOnArchived'))
      setItems(
         demands.filter((demand) => demand.status === DemandStatus.Archived && demands.find((x) => x.id === demand.parentId).status !== DemandStatus.Archived)
      )
   } else {
      const nextParentIndex = parentItem ? items.findIndex((x) => x.order > parentItem.order && x.level === parentItem.level) : undefined

      if (
         (items[index + 1] && droppedItem.level === 1 && items[index + 1].level !== droppedItem.level) ||
         (items[index + 1] && droppedItem.level !== 1 && items[index + 1].level > droppedItem.level)
      ) {
         handleMessage(dataContext.setRootState, 'warning', t('ActionNotSupported'), t('CantChangeParentOrLevelByDND'))
         resetItems(setItems, items, droppedItem, index)
      } else if (parentItem && (index < parentItemIndex || (index > nextParentIndex && nextParentIndex !== -1))) {
         handleMessage(dataContext.setRootState, 'warning', t('ActionNotSupported'), t('CantChangeParentByDND'))
         resetItems(setItems, items, droppedItem, index)
      } else {
         const demandsToUpdate: Demand[] = []
         relevantaItems.forEach((x, i) => {
            if (x.order !== i + 1) {
               demandsToUpdate.push({
                  ...x,
                  order: i + 1,
               })
            }
         })

         const updated = (await apiInstance.api.demandBatchUpdate(demandsToUpdate)).data
         const updatedDemandArray = [...items].map((x) => (updated.find((u) => u.id === x.id) ? updated.find((u) => u.id === x.id) : x))
         dataContext.setRootState((prev) => ({
            ...prev,
            demands: [...prev.demands].map((x) => (updated.find((u) => u.id === x.id) ? updated.find((u) => u.id === x.id) : x)),
         }))
         setItems(updatedDemandArray)
      }
   }
}
