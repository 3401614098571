import { Demand, DemandArea, DemandType, Question, QuestionStatus } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { UseFormReset } from 'react-hook-form'
import { TFunction } from 'i18next'
import { AddQuestion, DeleteQuestion, UpdateQuestion } from '../api/question'
import { AddItemToCollection, RemoveItemFromCollection, UpdateItemInCollection } from './stateHelper'
import { enumValuesToTranslate } from './enumHelper'
export type QuestionFormValues = {
   selectedQuestion: Question
   questions: Question[]
}
export const saveQuestion = async (
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
   dataContext: IDataContext,
   data: QuestionFormValues,
   reset: UseFormReset<QuestionFormValues>,
   t: TFunction<['translation', ...string[]], undefined>
): Promise<void> => {
   try {
      setIsSubmitting(true)
      const { questions } = dataContext.state

      const res = await UpdateQuestion(data.selectedQuestion)
      UpdateItemInCollection(dataContext.setRootState, questions, res, 'questions')

      const selectedQuestions = data.questions
      selectedQuestions[data.questions.findIndex((x) => x.order === res.order)] = res

      reset({ questions: selectedQuestions, selectedQuestion: res })
      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), t('QuestionSaved'))
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   } finally {
      setIsSubmitting(false)
   }
}

export const getNewQuestion = (order: number, name: string, parentId: string): Question => ({
   modifiedBy: name,
   modifiedDate: new Date().toISOString(),
   backgroundInformation: '',
   order,
   parentId,
   text: '',
   proposedAction: '',
   status: QuestionStatus.Ongoing,
   additionalModules: [],
   alternativeProcesses: [],
   answerOptions: [],
   leadershipPerspectives: [],
   organisationSizes: [],
   organisationTypes: [],
   process: undefined,
   snIs: [],
   subjects: [],
   subscriptions: [{ subscriptionId: 'B3CA142D-58B8-4E78-82E9-25132C0A88E7' }],
   answerType: null,
})

export const deleteQuestion = async (
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
   dataContext: IDataContext,
   data: QuestionFormValues,
   reset: UseFormReset<QuestionFormValues>,
   t: TFunction<['translation', ...string[]], undefined>
): Promise<void> => {
   try {
      setIsSubmitting(true)
      if (data.selectedQuestion.id) {
         await DeleteQuestion(data.selectedQuestion.id)
         RemoveItemFromCollection(dataContext.setRootState, dataContext.state.questions, data.selectedQuestion, 'questions')
      }

      reset((prev) => ({
         questions: prev.questions.filter((x) => x.id !== data.selectedQuestion.id),
         selectedQuestion:
            prev.questions[
               prev.questions.findIndex((x) => x.id === data.selectedQuestion.id) === 0
                  ? 0
                  : prev.questions.findIndex((x) => x.id === data.selectedQuestion.id) - 1
            ],
      }))
      reset((prev) => {
         const updatedQuestions = prev.questions.filter((x) => x.id !== data.selectedQuestion.id)
         const updatedSelectedQuestionIndex = updatedQuestions.findIndex((x) => x.id === data.selectedQuestion.id)
         const updatedSelectedQuestion =
            updatedSelectedQuestionIndex >= 0
               ? updatedQuestions[updatedSelectedQuestionIndex]
               : updatedSelectedQuestionIndex === 0 && updatedQuestions.length === 0
               ? null
               : updatedQuestions[updatedQuestions.length - 1]
         return {
            questions: updatedQuestions,
            selectedQuestion: updatedSelectedQuestion,
         }
      })
      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), t('QuestionSaved'))
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   } finally {
      setIsSubmitting(false)
   }
}

export const addQuestion = async (
   dataContext: IDataContext,
   reset: UseFormReset<QuestionFormValues>,
   setOpenModal: React.Dispatch<React.SetStateAction<'new' | 'undo' | 'badgeUndo'>>,
   name: string,
   demandId: string
): Promise<void> => {
   const questionsByOrderDesc = [...dataContext.state.questions.filter((x) => x.parentId === demandId).sort((a, b) => b.order - a.order)]
   const nextOrder = questionsByOrderDesc.length > 0 ? questionsByOrderDesc[0].order + 1 : 1
   const newQuestion = await AddQuestion(getNewQuestion(nextOrder, name, demandId))
   AddItemToCollection(dataContext.setRootState, dataContext.state.questions, newQuestion, 'questions')
   reset((prev) => ({
      selectedQuestion: newQuestion,
      questions: [...prev.questions.sort((a, b) => a.order - b.order), newQuestion],
   }))
   setOpenModal(null)
}

export const questionFakeIdGenerator = (question: Question, demand: Demand, t: TFunction<'translation', undefined>): string => {
   const type = enumValuesToTranslate(DemandType, t, demand.type, { lng: 'sv' })[0]
   const area = enumValuesToTranslate(DemandArea, t, demand.area, { lng: 'sv' })[0]
   return `Q${question.order}D${demand.level}${type}${area}${demand.order < 10 ? '0' + demand.order : demand.order}`
}

export const getMatchinQuestions = (questions: Question[], question: Question, parentDemand: Demand): Question[] => {
   const hasMatching = (types1: string[], types2: string[]): boolean => {
      return types1.some((type1) => types2.some((type2) => type1 === type2))
   }
   if (parentDemand.level === 3) return []

   return questions
      .filter((x) => x.id !== question.id)
      .filter((existingQuestion) => {
         return (
            hasMatching(
               existingQuestion.organisationTypes.map((x) => x.organisationTypeId),
               question.organisationTypes.map((x) => x.organisationTypeId)
            ) &&
            hasMatching(
               existingQuestion.organisationSizes.map((x) => x.organisationSizeId),
               question.organisationSizes.map((x) => x.organisationSizeId)
            ) &&
            hasMatching(
               existingQuestion.snIs.map((x) => x.sniId),
               question.snIs.map((x) => x.sniId)
            )
         )
      })
}
