import * as XLSX from 'xlsx'
import { fakeIdGenerator } from './demandHelper'
import { TFunction } from 'i18next'
import { questionFakeIdGenerator } from './questionHelper'
import { Dispatch, SetStateAction } from 'react'
import { enumValuesToTranslate } from './enumHelper'
import { AnswerType, Demand, DemandArea, DemandStatus, DemandSubType, DemandType, Question, QuestionStatus } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'

const generateRows = (dataContext: IDataContext, t: TFunction<'translation', undefined>, demand: Demand): any[] => {
   const { questions, demands, organisationSizes, organisationTypes, additionalModules, leadershipPerspectives, snis, subjects } = dataContext.state
   const relatedQuestions = questions.filter((question) => question.parentId === demand.id)
   const parentDemand = demands.find((x) => x.id === demand.parentId)
   const rows = []
   if (relatedQuestions.length === 0) {
      // If no questions are found for the demand, add a row with empty fields for questions information
      rows.push({
         'Krav-Nr': fakeIdGenerator(
            demand,
            t,
            demands.find((x) => x.id === demand.parentId)
         ),
         Krav: demand.text,
         Kravområde: enumValuesToTranslate(DemandArea, t, demand.area),
         Kravtyp: enumValuesToTranslate(DemandType, t, demand.type),
         Underkravtyp: enumValuesToTranslate(DemandSubType, t, demand.subType),
         Kravkälla: demand.source,
         Webbtext: demand.urlText ?? '',
         Webblänk: demand.url ?? '',
         Version: demand.version,
         Kravnivå: demand.level,
         Överkrav: parentDemand
            ? fakeIdGenerator(
                 parentDemand,
                 t,
                 demands.find((x) => x.id === parentDemand.parentId)
              ) ?? ''
            : '',
         'Status krav': enumValuesToTranslate(DemandStatus, t, demand.status),
         'Fråga-Nr': 'Ingen koppling',
         Fråga: 'Ingen koppling',
         'Org.storlek': '',
         'Org.form': '',
         'SNI-kod': '',
         Process: '',
         Ledningsperspektiv: '',
         Ämne: '',
         Tilläggsmodul: '',
         'Status fråga': '',
         Svarsform: '',
      })
   }

   relatedQuestions.forEach((question) => {
      const organisationSizeText =
         question.organisationSizes.length === organisationSizes.length
            ? 'Alla'
            : question.organisationSizes.map((x) => x.organisationSize?.text || '').join(', ')
      const organisationTypeText =
         question.organisationTypes.length === organisationTypes.length
            ? 'Alla'
            : question.organisationTypes.map((x) => x.organisationType?.text || '').join(', ')
      const sniText = question.snIs.length === snis.length ? 'Alla' : question.snIs.map((x) => x.sni?.code || '').join(', ')
      const leadershipPerspectiveText =
         question.leadershipPerspectives.length === leadershipPerspectives.length
            ? 'Alla'
            : question.leadershipPerspectives.map((x) => x.leadershipPerspective?.text || '').join(', ')
      const subjectText = question.subjects.length === subjects.length ? 'Alla' : question.subjects.map((x) => x.subject?.text || '').join(', ')
      const additionalModuleText =
         question.additionalModules.length === additionalModules.length
            ? 'Alla'
            : question.additionalModules.map((x) => x.additionalModule?.text || '').join(', ')

      rows.push({
         'Krav-Nr': fakeIdGenerator(
            demand,
            t,
            demands.find((x) => x.id === demand.parentId)
         ),
         Krav: demand.text,
         Kravområde: enumValuesToTranslate(DemandArea, t, demand.area),
         Kravtyp: enumValuesToTranslate(DemandType, t, demand.type),
         Underkravtyp: enumValuesToTranslate(DemandSubType, t, demand.subType),
         Kravkälla: demand.source,
         Webbtext: demand.urlText ?? '',
         Webblänk: demand.url ?? '',
         Version: demand.version,
         Kravnivå: demand.level,
         Överkrav: parentDemand
            ? fakeIdGenerator(
                 parentDemand,
                 t,
                 demands.find((x) => x.id === parentDemand.parentId)
              ) ?? ''
            : '',
         'Status krav': enumValuesToTranslate(DemandStatus, t, demand.status),
         'Fråga-Nr': questionFakeIdGenerator(question, demand, t),
         Fråga: question.text || 'Ingen koppling',
         'Org.storlek': organisationSizeText,
         'Org.form': organisationTypeText,
         'SNI-kod': sniText,
         Process: question.process?.process?.text || 'Ingen koppling',
         Ledningsperspektiv: leadershipPerspectiveText,
         Ämne: subjectText,
         Tilläggsmodul: additionalModuleText,
         'Status fråga': enumValuesToTranslate(QuestionStatus, t, question.status),
         Svarsform: enumValuesToTranslate(AnswerType, t, question.answerType),
      })
   })
   return rows
}
export const excelExporter = async (dataContext: IDataContext, t: TFunction<'translation', undefined>, setExportLoading: Dispatch<SetStateAction<boolean>>) => {
   setExportLoading(true)
   try {
      const { demands } = dataContext.state
      let rows: any[] = []

      demands
         .filter((x) => x.level === 1)
         .sort((a, b) => a.order - b.order)
         .forEach((demand) => {
            rows = [...rows, ...generateRows(dataContext, t, demand)]
            const childDemands = demands.filter((x) => x.parentId === demand.id).sort((a, b) => a.order - b.order)
            childDemands.forEach((childDemand) => {
               rows = [...rows, ...generateRows(dataContext, t, childDemand)]
               const children2 = demands.filter((x) => x.parentId === childDemand.id).sort((a, b) => a.order - b.order)
               children2.forEach((child2Demand) => {
                  rows = [...rows, ...generateRows(dataContext, t, child2Demand)]
               })
            })
         })
      const workbook = XLSX.utils.book_new()
      const ws1 = XLSX.utils.json_to_sheet(rows)
      XLSX.utils.book_append_sheet(workbook, ws1, 'Demands')

      XLSX.writeFile(workbook, 'export.xlsx')
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('DeleteError'), error.message)
   } finally {
      dataContext.handleMessage(dataContext.setRootState, 'success', t('ExportedSuccess'), '')
      setExportLoading(false)
   }
}
